// extracted by mini-css-extract-plugin
export var customEnterpriseDevBenefitsSection = "K_gJ";
export var customEnterpriseDevCallToActionSection = "K_gD";
export var customEnterpriseDevCaseSection = "K_gP";
export var customEnterpriseDevCostSection = "K_gK";
export var customEnterpriseDevExperienceSection = "K_gN";
export var customEnterpriseDevExpertiseSection = "K_gM";
export var customEnterpriseDevIndustriesSection = "K_gC";
export var customEnterpriseDevPracticesSection = "K_gL";
export var customEnterpriseDevPrimeSection = "K_gz";
export var customEnterpriseDevProcessSection = "K_gG";
export var customEnterpriseDevQuotesSection = "K_gQ";
export var customEnterpriseDevServicesSection = "K_gB";
export var customEnterpriseDevSolutionSection = "K_gF";
export var customEnterpriseDevTechSection = "K_gH";